import React from "react";
import PropTypes from "prop-types";
import { InnerType } from "@utils/types";
import ContentTitle from "@components/content-title";

const InterviewArea = ({ data }) => {
    return (
        <div className="personal-experience-inner mt--40">
            <div className="row">
                <div className="col">
                    <ContentTitle title="Unity Game Engine" />
                    <p>Institution: Arena Animation, Vadodara</p>
                </div>
            </div>
        </div>
    );
};

InterviewArea.propTypes = {
    data: PropTypes.shape({
        inner: PropTypes.arrayOf(PropTypes.shape(InnerType)),
    }),
};

export default InterviewArea;
