import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-scroll";
import cn from "classnames";
import Icon from "@ui/icon";
import { MenuType } from "@utils/types";

const MainMenu = ({ menus, className, navId }) => {
    return (
        <nav
            id={navId}
            className={cn("mainmenu-nav navbar-example2", className)}
        >
            <ul className="primary-menu nav nav-pills">
                {menus.map(({ id, text, path, icon }) => (
                    <li key={id} className="nav-item">
                        <Link
                            activeClass="active"
                            className="nav-link smoth-animation"
                            href={`#${path}`}
                            to={path}
                            spy={true}
                            smooth={true}
                            offset={-50}
                            duration={500}
                        >
                            {icon && <Icon name={icon} />}
                            {text}
                        </Link>
                    </li>
                ))}

                {/* View Resume Menu Item */}
                {/* <li className="nav-item ml-auto">
                    <a
                        href="/resume.pdf" // Update this with the actual resume URL
                        target="_blank"
                        rel="noopener noreferrer"
                        className="nav-link smoth-animation flex items-center"
                        title="View Resume"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="icon-eye"
                        >
                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                            <circle cx="12" cy="12" r="3"></circle>
                        </svg>{" "}
                    </a>
                </li> */}
            </ul>
        </nav>
    );
};

MainMenu.propTypes = {
    menus: PropTypes.arrayOf(PropTypes.shape(MenuType)).isRequired,
    navId: PropTypes.string,
    className: PropTypes.string,
};

MainMenu.defaultProps = {
    navId: "sideNav",
};

export default MainMenu;
