import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { InnerType } from "@utils/types";

const SkillArea = ({ data }) => {
    return (
        <div className="container">
            <div className="personal-experience-inner mt--40">
                <div className="row mt--50">
                    {data?.inner?.map((content, i) => (
                        <div
                            className={cn(
                                "col-lg-6 col-md-6 col-12 mb--30",
                                i !== 0 && "mt_sm--60"
                            )}
                            key={content.id}
                        >
                            <div className="content">
                                {/* Section Title */}
                                {content?.section_title && (
                                    <div className="content-title mb-4">
                                        {content?.section_title.subtitle && (
                                            <span className="subtitle text-sm text-muted">
                                                {
                                                    content?.section_title
                                                        .subtitle
                                                }
                                            </span>
                                        )}
                                        <h4 className="subtitle text-1xl font-semibold text-gray-800 mt-2">
                                            {content?.section_title.title}
                                        </h4>
                                    </div>
                                )}

                                {/* Displaying skills as badges side by side */}
                                <div className="d-flex flex-wrap gap-2">
                                    {content?.items?.map((item) => (
                                        <div
                                            key={item.id}
                                            className="skill-item mr-2 mb-3 py-1 px-3 bg-soft-gray text-dark rounded-pill text-sm font-medium shadow-sm hover:bg-soft-blue hover:text-white transition-all duration-300"
                                        >
                                            {item.title}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

SkillArea.propTypes = {
    data: PropTypes.shape({
        inner: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                section_title: PropTypes.shape({
                    title: PropTypes.string,
                    subtitle: PropTypes.string,
                }),
                items: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.number,
                        title: PropTypes.string,
                    })
                ),
            })
        ),
    }),
};

export default SkillArea;
